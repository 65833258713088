import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairExtensionforMen = () => {

  
  const seoData = {
    title: 'Hair Extensions for Men in Delhi - Radiance Hair Studio',
    description: 'Explore the ultimate solution for hair extensions for men in Delhi at Radiance Hair Studio. Your one-stop destination for natural-looking transformations.',
    keywords: ['Hair Extensions for men in Delhi'],
  };


  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Extension-for-Men's.jpg",
      content: (
        <div>
          <h2>Hair Extension for Men's</h2>
          <p>
            Watching our grandparents adoring their luscious mane has infused
            within us the DESIRE of owning long hair since our very CHILDHOOD. It
            seems that <a href="/gallery.php" style={{ color: "#373737"}}>FASHION</a> senses and LIFESTYLE had different plans to be
            implemented. But the DESIRE didn't take a LEAVE! To all our hair goals
            HAIR EXTENTION is the key.
          </p>
  
          <h2>Making it Clear!</h2>
          <p>
            The BEAUTY industry does not lack BRAINS! It came up with a solution
            to all our overnight HAIR GOALS. The all-new HAIR EXTENSION doesn't
            only add length but also volume to our existing mane. RADIANCE has a
            lot to offer! Starting from a variety of hair EXTENSION methods to
            match different hair types, color, and length. All your hair goals
            have just one stop solution at RADIANCE.
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-extensions-for-men1.png",
      largeImg: "/hair-extensions-for-men2.png",
      content: (
        <div>
          <p>
           <a href="/contact.php" style={{ color: "#373737"}}>Talking</a> about the types of HAIR EXTENSION available here are some at
            RADIANCE SALON appreciates.
          </p>
          <ul>
            <li>
              <strong>The Tape in</strong> - the <a href="/tape-in-and-clip-in-hair-extension.php" style={{ color: "#373737"}}>TAPE IN HAIR EXTENSION</a> is may be
              the easiest hair extensions process one can opt for. The human hair
              tape in is even better. Style accordingly and giving the gorgeous
              hair you got for months.
            </li>
            <li>
              <strong>The Weaving</strong> - the most trending hair extension
              process at present. Absolutely hustle free that will help one get
              fuller volumized hair with NO GLUE and NO HEAT.
            </li>
            <li>
              <strong>The Micro Ring</strong> - the microring process is for those
              owning long hair already. Adding to your scalp tons of volume to
              flaunt. The process is safe again with no heat and glue involved.
            </li>
            <li>
              <strong>The Clip-On method</strong> - Forget about an overnight hair
              goal, the CLIP ON technique helps women get that THICK MANE in no
              time! The easiest and for all.
            </li>
          </ul>
          <p>
            All the techniques come with a plus of NATURAL HUMAN HAIR extension.
            YOU HEARD THAT RIGHT! RADIANCE choose to offer the best for their
            customers. All natural human hair extensions adding volume and
            CONFIDENCE to all the LADIES. Our crown reflects our character be it
            any and of course worth a HEALTHY TRANSFORMATION! Radiance Salon makes
            that possible for you.
          </p>
          <h2>Benefits: Talking about Pros!</h2>
          <p>
            We hear you! Always wanted that thick luscious hair but never wanted
            to project that fakeness the synthetic extensions create! Keep those
            thoughts at BAY! The beauty industry has upgraded to the human hair
            extensions resolving all thoughts those were bothering you. Here are
            some benefits to guide you the way.
          </p>
          <ul>
            <li>
  
              The extensions that RADIANCE offers are all natural and comes in
              various color options. Making it easy to choose accordingly. The
              human hair doesn't project a fake look and blends into feels like A
              QUEEN WITH HER CROWN!
            </li>
            <li>
  
              Styling gets new shapes. Being bored with the hair you have?
              Extensions to rescue! Adding all that volume you desire, a style
              that hair whenever you wish to and change accordingly.
            </li>
            <li>
  
              If the COLORFUL CROWN is something that you dreamt of, RADIANCE has
              got your back. Coloring your hair with the natural your human hair
              extensions is way easier. Put on the desired color to match up your
              personality and care that mane as you do for yours. Sorted with
              that!
            </li>
            <li>
  
              WORRY LESS AND ACT MORE! Wearing all your worries while carrying
              that extension doesn't suit you! Human hair extensions deprive all
              your worries in a minute and let you flaunt what you got.
            </li>
          </ul>
          <p>
            So BID BYE to all your past and say HELLO to hair extensions with
            RADIANCE!
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs: All Questions Answered!",
      description:
        "We understand all your concerns and questions going on in your head. So here are some that we choose to answer for you.",
      description2: " Hopefully, we have managed to clear all those questions that were stopping you from getting one. RADIANCE awaits you with a free consultation",
      faq: [
        {
          title: "Does hair extensions damage the natural hair?",
          description:
            "Hair extensions don't damage the already existing hair. Hair extensions are naturally clips that are attached to the roots of existing hair or onto the scalp. This doesn't affect one's natural hair.",
        },
        {
          title: "What extra care is needed for extensions?",
          description:
            "As the hair extensions don't grow from the scalp, they are deprived of natural oils. Regular conditioning and moisturizing are important to keep that volume healthy.",
        },
        {
          title: "How to choose the right color and style for the extension?",
          description:
            "That might sound easy but choosing the right color and perfect style could be confusing at times. The type and style you need to adopt, depend completely on your hair type, texture, and length. Here at RADIANCE, we have our professional hair experts to help you with that.",
        },
        {
          title: "For how long can I wear the hair extensions?  ",
          description:
            "The time of wearing any hair extensions varies from the one to another. Also, the way you decide to take care of those volumes also affects the time you can wear it one.",
        },
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} />
        <Layout>
          <TitleBar2 title="Hair Extension for Men's" banner="/hair-extension-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Extension for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairExtensionforMen;
